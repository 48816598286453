<template>
  <div class="container">
    <div style="height: 200px"></div>
    <el-card class="box_card">
      <div slot="header">
        <span>系统登录</span>
      </div>
      <el-input placeholder="请输入账号" v-model="account">
        <template slot="prepend">账号</template>
      </el-input>
      <div style="height: 30px"></div>
      <el-input placeholder="请输入密码" v-model="password" show-password>
        <template slot="prepend">密码</template>
      </el-input>
      <div style="margin-top: 15px">
        <el-checkbox v-model="checked" @change="isSaveAccount"
          >记住账号</el-checkbox
        >
      </div>
      <div style="margin-top: 30px; margin-bottom: 30px; text-align: center">
        <el-button type="primary" v-on:click="login" :loading="loading"
          >立即登录</el-button
        >
      </div>
    </el-card>
  </div>
</template>

<script>
import router from "../router";
import api from "../api";

export default {
  data() {
    return {
      account: "",
      password: "",
      checked: true,
      loading: false,
    };
  },
  created() {
    this.checked = localStorage.getItem("isSaveAccount") === "true";
    if (this.checked) {
      this.account = localStorage.getItem("account");
    }
  },
  methods: {
    login() {
      if (!this.account || !this.password) {
        this.$message({
          showClose: true,
          message: "用户名密码不能为空",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      api
        .userLogin({
          account: this.account,
          password: this.$md5(this.password),
        })
        .then((res) => {
          if (res.data.success === "1") {
            if (this.checked) {
              localStorage.setItem("account", this.account);
            }
            this.$Cookies.set("token", res.data.data.token);
            localStorage.setItem("usercode", res.data.data.usercode);
            localStorage.setItem("username", res.data.data.username);
            localStorage.setItem("userfullname", res.data.data.userfullname);
            localStorage.setItem("logindata", JSON.stringify(res.data.data));
            localStorage.setItem("qrcodeurl", res.data.data.qrcodeurl);
            localStorage.setItem("ishospital", res.data.data.ishospital);
            router.push("/dashboard");
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$message({
            showClose: true,
            message: "登录失败，请检查网络连接",
            type: "warning",
          });
          console.log(err);
          this.loading = false;
        });
    },
    isSaveAccount(val) {
      localStorage.setItem("isSaveAccount", val);
      if (!val) {
        localStorage.removeItem("account");
      }
    },
  },
};
</script>

<style scoped>
.container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #2d3a4b;
}
.box_card {
  margin: 0 auto;
  width: 480px;
}
</style>
